/* Existing styles */
nav {
  position: absolute;  
  top: 0;
  height: 20vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #2f2f2f;
  overflow: hidden;
  margin: 20px;
  box-sizing: border-box;
  width: calc(100% - 40px);
  font-family: roboto;
  z-index: 100;  
  transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out;
}

.navInnerWrapper{
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.nav-collapsed{
  position: fixed;
  height:10vh!important;
  margin:0!important;
  width: 100vw!important;  
}

/* New styles for hiding/collapsing behavior */
.nav-hidden {
  transform: translateY(-100%); /* Move the navbar up when hidden */
  }

.nav-invis{
  visibility: hidden;
}

nav img {
  height: 75px;
}

nav .navLogo{
    margin: 15px 0 10px;
}

nav ul {
  margin-top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  padding: 0;
}

nav li {
  padding: 10px 2.5vw 10px 0;
  display: flex;
  text-align: center;
}

nav a {
  text-decoration: none;
  color: white;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 1rem;
  border-bottom: 2px solid rgb(85, 116, 110);
  transition: border-bottom 0.2s ease-in;
}

#basket a {
border-bottom: none;
}

nav .active-link{  
  border-bottom: 2px solid rgb(255, 212, 42);
}

nav a:hover {
  border-bottom: 2px solid rgb(255, 212, 42);
}

