/* src/components/styling/cart.css */

#cartWrapper{
  width: 100vw;
  max-width: 100%;
  min-height: 100vh;
  
}

#cartBox {
  margin: 0 auto;
  padding: 100px 50px;
  max-width: 1200px;
  background: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background-color: #0A0A0A;
    color:#F6F8FF;  
  width: 100%;
  box-sizing: border-box;
  font-family: Garamond;
}

#cartBox h2 {
  margin-bottom: 50px;
  border-bottom: 2px solid #fed126ff;
  padding-bottom: 10px;
}

.cart-content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.cart-items {
  width: 60%;
}

.cart-items ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.cart-items ul li,
.cart-basic-items ul li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 25px;
  background-color: #2F2F2F69;
  -webkit-backdrop-filter: blur(7.5px);
  backdrop-filter: blur(7.5px);
  margin-bottom:25px;
  border-radius: 5px;
  min-height: 125px;
}


.item-info {
  display: flex;
  align-items: center;
  width: 100%;
}

.item-info h3{
  color:#fed126ff;
  margin-top: 0;
  margin-bottom: 10px;
  margin-left: 5px;
}


.item-info-imageWrapper {
  width: 100px;
  height: 100px;
  margin-right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 4px;
  background-color: #2F2F2F;
}



.item-info-imageWrapper img{
  height: 100%;
  width: auto;
}

.quantity-controls {
  display: flex;
  align-items: center;
  padding: 5px;
  border-radius: 4px;
  width: fit-content;
  background-color: #2F2F2F;
}

.quantity-controls button {
  background-color: none;
  border: none;
  margin: 0 5px;
  background-color: transparent;
  display: flex;
  padding: 3px;
  border-radius: 5px;
}
.quantity-controls button img{
  cursor: pointer; 
}

.remove-item-btn {
  color: white;
  border: none;
  cursor: pointer;
}

.cart-actions {
  margin-top: 20px;
}

.clear-cart-btn {
  border: none;
  border-radius: 4px;
  padding: 10px 0;
  cursor: pointer;
  background-color: transparent;
  display: flex;
  font-size: 0.9rem;
  text-decoration: underline;
}

.clear-cart-btn img{
  margin-right: 5px;
}

.cart-overview {
  width: 35%;
  background-color: #2F2F2F69;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-left: 50px;
}

.paymentIconsP{
  text-align: center;
}

.cart-overview h2 {
  margin-bottom: 20px;
  border-bottom: 2px solid #fed126ff;
  padding-bottom: 10px;
}

.overview-details, .overview-total {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.overview-details:nth-of-type(2){
  border-bottom: 1px solid #fed126ff;
  padding-bottom: 15px;
}

.overview-details:nth-of-type(2) p{
  margin: 0;
}

.checkout-btn {
  background-color: rgb(255, 212, 41);
  color: rgb(47, 47, 47);
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  cursor: pointer;
  width: 100%;
  transition: background-color 0.1s ease-out;
}

.checkout-btn:hover{
 background-color: rgb(176, 149, 42);
}

.paymentIcons {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.paymentIcons img{
  height: 40px;
  margin: 0 5px;
}

.paymentIcons img:last-of-type{
  height: 30px;
}

.priceDetails div{
  display: flex;
  justify-content: space-between;
}

.priceDetails div:nth-last-of-type(2){
  border-bottom: 1px solid #fed126ff;
}

.priceDetails h3{
  border-bottom: none!important;
}

.priceDetails div:first-of-type{
  border-top: 1px solid #fed126ff;
}

.priceDetails div:first-of-type h3{
  margin-bottom: 0!important;
  padding: 0!important;
}