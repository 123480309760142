.slick-slider{
  overflow: hidden;
}

.slick-slide {
  outline: none
}
.slick-slide div {
  outline: none;
 }

 .slick-slide {
  &:focus, a {
    outline: none;
  }
}