/* src/components/styling/analytics.css */

.analytics-container {
    margin: 100px auto;
    padding: 20px;
    max-width: 1200px;
    background: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    background-color: rgb(242, 243, 236);
    color: rgb(47, 47, 47);
    width: 100%;
    box-sizing: border-box;
  }
  
  .analytics-container h1 {
    margin-bottom: 20px;
    border-bottom: 2px solid rgb(85, 116, 110);
    padding-bottom: 10px;
    font-size: 24px;
  }
  