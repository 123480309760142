@media (min-width: 800px) and (max-width: 1200px){

/* 

    .product-page{
        flex-direction: column;
        padding: 50px 7.5vw 140px;
    }
    
    .product-image{
        margin-right: 0;
        margin-bottom: 50px;
        padding: 0 15px;
        width: 100vw;
        box-sizing: border-box;
        max-width: inherit;
    }
    
    
    .slider-for {
        height: 75vw;
      }
    
    .slider-for img {
        height: 75vw!important;
    }
    
    .slider-nav .slick-slide {
        width: 25vw !important;
        height: 20vw;
    }
    
    .slider-nav .slick-track{
        background-color: #2F2F2F69;
    }
    
    .product-content p{
        font-size: 0.8rem;
        text-align: justify;
    }
    
    .product-content .logoWrapper{
        display: flex;
        justify-content: center;
        width: 100%;
    }
    
    .productTitle p,
    .productTitle h2,
    .productTitle h1{
        text-align: center!important;
    }
    
    .productTitle h2{
        margin-bottom: 25px;
    }
    
    .product-content h1 {
        margin-top: 25px;
        font-size: 1.5rem;
    }
    
    .product-content h2 {
        font-size: 1.5rem;
    }
    
    .product-content li{
        font-size: 0.8rem;
    }

    .product-description ,
    .product-description ul{
        margin-top: 25px;
    }

    
    .product-details{
        justify-content: center;
  margin-top: 50px;
    }
    
    
    .order-section{
        flex-direction: column;
        font-size: 0.9rem;
        margin-top: 50px;
    }
    
    .order-section div{
        display: flex;
        align-items: center;
        margin-bottom: 15px;
    }
    
    .order-section button{
        margin-left: 0!important;
    }
    

    .availability {
        text-align: center!important;
    } */

    .product-page{
        padding: 100px 5vw 140px;
    }

    .product-content p{
        font-size: 0.9rem;
        text-align: justify;
    }

    .product-page .product-image{
        margin-right: 5vw;
    }

    .product-page .slider-for {
        height: 50vw;
      }
    
      .product-page .slider-for img {
        height: 50vw!important;
        width: auto;
        object-fit: contain;
    }
    
    .product-page .slider-nav .slick-slide {
        width: 15vw !important;
        height: 10vw;
    }

    .product-content h1 {
        margin-top: 25px;
        font-size: 1.6rem;
    }
    
    .product-content h2 {
        font-size: 1.6rem;
    }
    
    .product-content li{
        font-size: 0.9rem;
    }

    .order-section{
        flex-direction: column;
        font-size: 1rem;
        margin-top: 50px;
    }

    .order-section{
        flex-direction: column;
        font-size: 0.9rem;
        margin-top: 50px;
    }
    
    .order-section div{
        display: flex;
        align-items: center;
        margin-bottom: 15px;
    }
    
    .order-section button{
        margin-left: 0!important;
    }

}