#pdfListBox h3{
  border-bottom: 2px solid rgb(85, 116, 110);
  padding-bottom: 10px;
}

.pdf-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 0.5fr 1.5fr;
    gap: 10px;
  }
  
  .pdf-grid-header {
    display: contents;
    font-weight: bold;
    color: rgb(47, 47, 47);
  }
  
  .pdf-grid-row {
    display: contents;
  }
  
  .pdf-grid div {
    padding: 10px;
    border-bottom: 1px solid #ddd;
  }
 
  .buttonsDiv{
    display: flex;
  align-items: center;
  }

  .buttonsDiv span{
   margin-right:5px;
  }

  .buttonsDiv button{
    margin-right: 5px;
  }
  
  .btn-delete {
    padding: 5px 10px;
    border: none;
    background-color: #FE5F55;
    color: white;
    border-radius: 5px;
    transition: background-color 0.2s ease-out;
  }
  
  .btn-delete:hover {
    background-color: #d85147;
  }
  
  .btn-setActive {
  background-color: #0CCE6B;
  border: none;
  border-radius: 5px;
  padding: 5px;
  color: white;
  transition: background-color 0.2s ease-out;
  }

  .btn-download {
    background-color: #7692FF;
    border:none;
    border-radius: 5px;
    color:white;
    padding: 5px;
    transition: background-color 0.2s ease-out;
  }
  
  .btn-setActive:hover {
    background-color: #1ca55e;
  }

  .btn-download:hover {
    background-color: #4f6cdf;
  }
  

  @media (max-width: 1200px) {
    .pdf-grid {
      display: block;
    }
  
    .pdf-grid-header, .pdf-grid-row {
      display: grid;
      grid-template-columns: 1fr;
      gap: 5px;
      padding: 10px;
      border-bottom: 1px solid #ddd;
    }
  
    .pdf-grid-row div{
      display: flex;
      justify-content: space-between;
    }

    .pdf-grid-row div span:first-child{

      font-weight: bold;
    }
    .pdf-grid-row div span:last-child{
      text-align: end;
    }

    .pdf-grid-header {
      font-weight: bold;
    }
  
    .pdf-grid-row > div {
      padding: 5px 0;
    }
  
    .buttonsDiv {
      display: flex;
      flex-wrap: wrap;
      gap: 5px;
      margin-top: 10px;
    }
  
    .buttonsDiv button {
      flex: 1;
      min-width: 100px;
      padding: 8px;
    }
  
    .btn-delete, .btn-setActive, .btn-download {
      padding: 8px;
      font-size: 0.9em;
      width: 100%;
    }
  }
  