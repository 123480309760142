@media (max-width: 1400px) {

  
  #storeBox{
    overflow: hidden;
    width: 100vw;
  }

  #storeBoxInfo{
      height: auto;
      min-height: auto;
      padding: 50px 5vw;
      margin: 100px 0;
      position: relative;
      z-index: 2;
      background: rgba(233, 226, 208, 0.61);
      backdrop-filter: blur(12px);
-webkit-backdrop-filter: blur(12px);
border-radius: 5px;
box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
max-width: 600px;
  }

  #storeBoxSplitter{
      display: flex;
      flex-direction: column;
  }

  #storeBoxSplitter{
      padding: 25px;
      position: relative;
      margin-bottom: 50px;
  }

  #storeBoxSplitter p{
      font-size: 0.8rem;
  }

  #storeBoxImage{
      position: absolute;
      z-index: 1;
      height: 100%;
      width: 100vw;
      overflow: hidden;
      display: flex;
      justify-content: center;
  }

  #storeBoxBottomText{
    flex-direction: column;
    padding: 50px calc(5vw + 25px);
    max-width: 1000px;
  }

  #storeBoxBottomText p{

    font-size: 0.85rem;
  }


  .kunstRoofLogoWrapper{

    margin:50px 0;
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .kunstRoofLogo{
    height: 150px;
    margin: 0;
  }

  #storeBox .buttonsBox{
    flex-direction: row;
  }

  #storeBox .buttonsBox button{
    margin-bottom: 0;
    height: 40px;
  }

  .textlogo {
    left: 0;
    width: 100%;
    height: auto;
    top: 0;
    max-width: 400px;
  }



}




@media (min-width: 750px) and (max-width: 1400px) {

.liquorShowElement img{
  margin-right: 50px;
}

.reverseElement img{
  margin-right: 0;
  margin-left: 50px;
}

#aboutImageSlider{
  margin-right: 0;
  width: 100%;  
  max-width: inherit;
}

#aboutBox img{
  max-height: 600px;
  margin: 50px auto 60px;
  width: auto;

}

  #aboutSplitter {
    flex-direction: column-reverse;
  }

  #aboutInfo {
    max-width: 800px;
    width: 100%;
  }

  #contactSplitter {
    flex-direction: column;
    width: 100%;
    padding: 0 5vw;
    box-sizing: border-box;
  }

  #contactInfoWrapper {
    margin-right: 0;
  }

  #addressWrapper{
    margin: 100px 0 50px;
  }

  #contactFormWrapper{
    margin: 50px auto;
  }

  #storeBoxImage {
    overflow: hidden;
  }


  #satiataBox{
    padding:50px 0;
  }

  #satiataBox .infoWrapper{
    max-width: 1000px;
  }

  #satiataBox .infoBox {
    flex-direction: column;    
    margin-bottom: 0;
  }

  .infoImageWrapper{
    margin-right: 5vw;
  }


  #storeBoxImage img {
    height: fit-content;
    width: 100%;
  }


}


@media (max-width: 750px) {

    #homeBox h1{
        margin: 0 0 25px;
    }

    .background-image {
        width: 100%;
        height: 100vh;
        top: calc( 20vh + 20px);
        visibility: hidden;
    }

    .splitterBox{
        flex-direction: column;
    }

    #homeBox .infoBox {
        width: 100%;
        background-image: url('../assets/restraunt.jpg');
        height: auto;
    min-height: 100vh;
    padding: 50px 5vw;
    }

    .infoBox p{
        font-size: 0.8rem;
    }
    

    .infoWrapper {
        padding: 50px 25px;
        max-width: 100%;
        box-sizing: border-box;
    }

    .buttonsBox {
        flex-direction: column;
    }

    .buttonsBox button{
        margin-bottom: 15px;
        font-size: 0.8rem;
    height: 35px;
    width: 165px;
    }

    .imageBox{
        width: 100%;
        padding: 100px 25px 50px;
        min-height: 100vh;
        height: auto;
    }



    #satiataBox .infoBox{
        flex-direction: column;
    }

    .infoImageWrapper{
        margin: 0 0 50px;
    }

    .octoImage {
        width: 90vw;
        max-width: 400px;
    }

    #satiataBox .infoBox {
        width: 100%;
        height: auto;
        min-height: 100vh;
        padding: 50px 5vw;
    }

    .imageBoxSatiata {
        width: 100%;
        height: 400px;
        margin-bottom: 100px;
        margin: 0 auto 100px;
    }

    .slick-dots button{
        background-color: transparent!important;
    }


    #liquorBoxTextWrapper{
        padding: 0 calc(25px + 5vw);
    }

    #liquorBoxTextWrapper p{
        font-size: 0.85rem;
    }

    .liquorShowBox {
        padding: 0 5vw;
      }

    .liquorShowElement{
        min-height: 100vh;
        height: auto;
        flex-direction: column;
    }

    .liquorShowElement div{
        width: 100%;
        max-width: auto;
    }

    .liqourShowInfo{
        padding: 0 25px;
        box-sizing: border-box;
    }

    .liqourShowInfo p{
        font-size: 0.85rem;
    }

    .liquorShowElement img {
        height: auto;
        max-width: 300px;
    margin: 25px auto 50px;
      }

    .liqourButtonWrapper button{
        align-self: stretch;
    }

      .craftmanWrapper{
        margin: 0 0 100px;
      }

      .craftmanTextWrapper{
        margin: 150px 5vw;
        padding: 50px 25px;
      }

      .craftmanWrapper img {
        width: auto;
        height: calc(100% + 250px);
        top: -250px;
        margin: 0;
      }

      #craftmanLogoWrapper img{
        top:0;
      }

      #aboutBox{
        padding: 100px 5vw;
      }

      #aboutBox h1 {
        margin: 0 0 50px;
        text-align: start !important;
      }

      #aboutBox h2{
        text-align: start !important;
      }

      #aboutBox img{
        margin: 25px auto 50px;
        max-width: 100%;
        width: auto;
        max-height: 500px;
      }

      #aboutBox p{
        font-size: 0.85rem;
      }

      #aboutSplitter{
        flex-direction: column;
      }

      #aboutImageSlider {
        margin: 0;
      }

      #aboutImageSlider,
      #aboutInfo{
        width: 100%;
        box-sizing: border-box;
      }

      #aboutInfo{
        padding: 25px;
      }

      #aboutBottomText{

        padding: 25px;
        margin: 25px 0;
      }

      #contactBox h1 {
        margin: 50px 0;
      }

      #contactBox p,
      #contactBox a{
        font-size: 0.85rem!important;
      }

      #contactSplitter{
        flex-direction: column;
        width: 100%;
    padding: 0 5vw;
    box-sizing: border-box;
      }

      #contactInfoWrapper {
        padding:0 25px;
        box-sizing: border-box;
        margin:0;
      }

      #addressWrapper{
        flex-direction: column;
        margin: 100px 0 50px;
      }

     .addressCol {
            max-width: none;
            min-width: 200px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-bottom: 50px;
      }

      .addressStripe{
        width: 100%;
        border-bottom: 1px solid rgb(85, 116, 110);
        margin-bottom: 50px;
      }

      #addressWrapper h3{
        margin: 0 0 15px;
      }

      .mapsSplitter{
        margin-bottom: 15px;
      }

      #openingWrapper{
        margin-bottom: 15px;
      }

      #contactFormWrapper{
        max-width: 100%!important;
        min-width: auto!important;
        box-sizing: border-box!important;
        min-width: auto!important;
        padding: 50px 25px!important;
      }

      .menuSelectorWrapper{
        max-width: 90vw;
        box-sizing: border-box;
      }

    

  }


  @media (min-width: 1200px) and (max-width: 1400px){
    #aboutBox{
      padding-bottom: 100px!important;
    }
   
  }

  @media (min-width: 450px) and (max-width: 610px){
    
    .menuSelectorWrapper{
      padding: 50px 75px!important;
    }

  }
  

  @media (max-width: 450px){

    .menuSelectorWrapper{
      padding: 50px 50px!important;
    }

    .liquorShowElement img {
        margin: 25px auto;        
    }

    .menuSelectButtons{
      display: flex;
    }

    #menuSelectorBox div button:first-of-type{
      margin: 0 0 15px;
    }

    #menuSelectorBox div button{
      align-self: stretch;
    }


    #menuSelectorBox div button:first-of-type {
      margin-right: 0!important;
    }

    .menuSelectButtons{
      display: flex;
      flex-direction: column;
      width: 60%;
  margin: 40px auto 0;
  min-width: 125px;
    }   

       
  .textlogo{
    right: -50%;
    height: auto;
    width: 100vw;
    top: 4%;
    left: -50%;
  }
  }
