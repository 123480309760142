@media (min-width: 750px) and (max-width: 1200px) {
.productInfoSpan {
  width: 500px;
}
}

@media (max-width: 750px) {

  #ageWrapper{
    position: fixed;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    z-index: 1000;
    background-color: #0A0A0A;
    color:#F6F8FF; 
    display: flex;
    justify-content: center;
    font-family: Garamond;
    align-items: center;
  }
  
  #ageBox{
    padding: 50px 25px;
    border-radius: 5px;
    margin: 0 5vw;  
  }
  
  #ageLogoWrapper{
    margin:0 0 25px;
    display: flex;
    justify-content: center;
  }
  
  #ageLogoWrapper img{
    height: 100px;
  }
  
  #ageBox h1{
    margin: 0 0 25px;
  }
  
  #ageButtons{
    display: flex;
    justify-content: center;
    align-items: center;
  }

    .productBoxBackgroundImage:nth-of-type(1){
        left: 0;
        top: 350px;
      height: 250px;
    }
    
    .productBoxBackgroundImage:nth-of-type(2){
       left: 0; 
       bottom: 50%;
      height: 250px;
    }
    
    .productBoxBackgroundImage:nth-of-type(3){
        right: -2px;
        top: 25%;
      height: 250px;
    }
    
    .productBoxBackgroundImage:nth-of-type(4){
        right: -2px;
      bottom:0; 
      height: 200px;
    }
    
    .productBoxBackgroundImage:nth-of-type(5){
        right: 20vw;
      bottom:30%; 
      height: 100px;
    }
    
    .productBoxBackgroundImage:nth-of-type(6){
        right: 15vw;
        left: auto;
      bottom:20%;
      top:auto; 
      height: 100px;
    }

#productBoxWrapper{
    padding: 0 5vw 200px;
}

.product{
    max-width: 100%;
    display: flex;
  flex-direction: column;
 align-items: center;
 margin-bottom: 50px;
}

.productImageWrapper{
    margin-bottom: 25px;
}

.productTitle{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 15px;
    text-align: center;
}

.productInfo{ 
    margin-left: 0;
}

.productInfo h2 span{
  text-align: center!important;
}

.productInfo span{
        width: fit-content;
}


.productButtons{
    margin-top: 25px;
}

.productButtons .hightlightsLink button{
    padding: 5px 25px;
}





}