@media (max-width: 450px) {

.product-page{
    flex-direction: column;
    padding: 50px 25px 140px;
}

.product-page .product-image{
    margin-right: 0;
    margin-bottom: 50px;
    padding: 0 15px;
    width: 100vw;
    box-sizing: border-box;
    max-width: inherit;
}


.product-page .slider-for {
    height: 400px;
  }

.product-page .slider-for img {
    height: 400px !important;
}

.product-page .slider-nav .slick-slide {
    width: 100px !important;
}

.product-page .slider-nav .slick-track{
    background-color: #2F2F2F69;
}

.product-content p{
    font-size: 0.8rem;
    text-align: justify;
}

.product-content .logoWrapper{
    display: flex;
    justify-content: center;
    width: 100%;
}

.productTitle p,
.productTitle h2,
.productTitle h1{
    text-align: center!important;
}

.productTitle h2{
    margin-bottom: 25px;
}

.product-content h1 {
    margin-top: 25px;
    font-size: 1.5rem;
}

.product-content h2 {
    font-size: 1.5rem;
}

.product-content li{
    font-size: 0.8rem;
}

.order-section{
    flex-direction: column;
    font-size: 0.9rem;
    margin-top: 50px;
}

.order-section div{
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.order-section button{
    margin-left: 0!important;
}

.availability {
    text-align: center!important;
}

}



@media (min-width: 450px) and (max-width: 800px){



    .product-page{
        flex-direction: column;
        padding: 50px 7.5vw 140px;
    }
    
    .product-page .product-image{
        margin-right: 0;
        margin-bottom: 50px;
        padding: 0 15px;
        width: 100vw;
        box-sizing: border-box;
        max-width: inherit;
    }
    
    
    .product-page .slider-for {
        height: 75vw;
      }
    
    .product-page .slider-for img {
        height: 75vw!important;
    }
    
    .product-page .slider-nav .slick-slide {
        width: 25vw !important;
        height: 20vw;
    }
    
    .product-page .slider-nav .slick-track{
        background-color: #2F2F2F69;
    }
    
    .product-content p{
        font-size: 0.8rem;
        text-align: justify;
    }
    
    .product-content .logoWrapper{
        display: flex;
        justify-content: center;
        width: 100%;
    }
    
    .productTitle p,
    .productTitle h2,
    .productTitle h1{
        text-align: center!important;
    }
    
    .productTitle h2{
        margin-bottom: 25px;
    }
    
    .product-content h1 {
        margin-top: 25px;
        font-size: 1.5rem;
    }
    
    .product-content h2 {
        font-size: 1.5rem;
    }
    
    .product-content li{
        font-size: 0.8rem;
    }

    .product-description ,
    .product-description ul{
        margin-top: 25px;
    }

    
    .product-details{
        justify-content: center;
  margin-top: 50px;
    }
    
    
    .order-section{
        flex-direction: column;
        font-size: 0.9rem;
        margin-top: 50px;
    }
    
    .order-section div{
        display: flex;
        align-items: center;
        margin-bottom: 15px;
    }
    
    .order-section button{
        margin-left: 0!important;
    }
    

    .availability {
        text-align: center!important;
    }


}