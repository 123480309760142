@media (max-width: 1200px) {

  #cartBox {
    margin: 0 auto;
    padding: 50px 5vw 100px;

    }

}

@media (max-width: 950px) {

   

    .cart-content{
        flex-direction: column;
    }

    .cart-items{
        margin-bottom: 50px;
        width: 100%;
    }

    .cart-overview {

        width: 100%;
        margin-left: 0;
        box-sizing: border-box;
        max-width: 600px;
        margin: auto;
    }

    .item-info-text{
        width: calc(100% - 120px);
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      .item-info-text p{
        margin: 0 0 15px 5px;
      }


      .clear-cart-btn {
        justify-content: center;
      }
  

}
/* 

@media (min-width: 950px) and (max-width: 1200px) {

  .cart-content{
    flex-direction: row;
} */
/* 

} */