
#splashScreen{
    width: 100vw;
    max-width: 100%;
    height: 100vh;
    background-color: #2F2F2F;
    position: fixed;
    z-index: 10000;
    display: flex;
  justify-content: center;
  align-items: center;

  transition: opacity 0.25s ease-out;
}

#splashScreen img{
    height: 100px;
}

#splashScreen.fade-out {
    opacity: 0;
    pointer-events: none; /* Prevent interactions when faded out */
  
  }

#homeWrapper{
    height: 100vh;
    width: 100vw;

    background-image: url('../assets/gin-bg.jpg');
    /* background-image: url('../assets/bg5.jpg'); */
    background-size: cover;
    background-position: center;
    max-width:100%;
 
}

#homeOverlay{
    position:absolute;
    height: 100vh;
    width: 100vw;
    z-index: 2;
    background-color: rgba(86, 86, 86, 0.19);
    padding: 8px;
    border: 20px solid rgb(255, 212, 42);
    box-sizing: border-box;
    border-bottom: 80px solid rgb(255, 212, 42);
    max-width:100%;
}

#homeOverlay div{
    border-radius: 16px;
}

#homeContent{
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: end;
    flex-direction: column;
    z-index: 3;
    position: relative;
}

.btnBox{
    display: flex;
    justify-content: space-between;
    align-items: center;    
    padding: 0 40px 20px;
  height: 40px;
  position: relative;
  
  font-size: 0.7rem;
  font-family: merriweather;
}

.btnBox a{
    height: 50px;
    display: flex;
    justify-content: start;
  align-items: center;
  text-decoration: none;
  color:black;
}


.btnBox button{
    cursor: pointer;
}

.instaLogoWrapper{
    height: 30px;
    width: 30px;
}

#instaLogo{
    height: 100%;
    width: 100%;
}

.btnWrapper{
    height: 100%;
    padding: 5px;
}

.addresses{
    display: flex;
    height: 100%;
  align-items: center;
}

.addressWrapper{
    display: flex;
    justify-content: center;
    align-items: center;
}

.addressImageWrapper{
    height: 20px;
    width: 20px;
    margin-right: 5px!important;
}

.addressWrapper img{
 height: 100%;
 width: 100%;
}

.textBox{
    display: flex;
    flex-direction: column;
    margin-right: 20px;
}

#homeContent button{
    height: 50px;
    right:0;
    left:0;
    margin: auto;
    position: absolute;
    padding:0 20px;
    width: fit-content;
    border-radius: 5px;
    color: white;
    background-color: #008BF8;
    border: none;
    font-family:prompt;
    text-transform: uppercase;
    font-size: 0.8rem;
    font-weight: 500;
    -webkit-box-shadow:-7px 6px 20px -4px rgba(124, 121, 121, 0.62);
-moz-box-shadow: -7px 6px 20px -4px rgba(124, 121, 121, 0.62);
box-shadow:-7px 6px 20px -4px rgba(124, 121, 121, 0.62);
transition: background-color 0.1s ease-out;
}

#homeContent button:hover{
    background-color: #2069a0;
}

#homeWrapper .highlights{
    height: 35vh;
    margin: 0 5vw 8vh;
    display: flex;
    justify-content: center;
}

#homeWrapper .hightlightsLink{

    width: 25%;
    margin: 0 2vw;
    max-width: 250px;

}


.highlights div{
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    -webkit-box-shadow: -1px 3px 52px -10px rgba(0,0,0,0.75);
-moz-box-shadow: -1px 3px 52px -10px rgba(0,0,0,0.75);
box-shadow: -1px 3px 52px -10px rgba(0,0,0,0.75);
    display: flex;
    flex-direction: column;
    justify-content: end;
    font-family: merriweather;
  color: white;
  box-sizing: border-box;
  cursor:pointer;
  position: relative;
  overflow: hidden;
  transition: transform 0.1s ease-out;

}

.highlights div img{
    position: absolute;
    min-height: 100%;
    width: 100%;
}

.highlights div:hover{
    -webkit-box-shadow: -1px 3px 32px 4px rgba(0,0,0,0.75);
    -moz-box-shadow: -1px 3px 32px 4px rgba(0,0,0,0.75);
    box-shadow: -1px 3px 32px 4px rgba(0,0,0,0.75);
transform: scale(1.02)!important;
}

.highlights div h2{

    background: linear-gradient( 180deg, hsla(0, 0%, 0%, 0) 0%, hsla(0, 0%, 0%, 0.3) 10%, hsl(0, 0%, 0%) 100% );
    width: 100%;
    margin:0;
    height:100px;
    padding-top: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    font-size: 1.2rem;
    position: relative;
}


/* #liqourDiv{
    background-image: url('../assets/liqour.jpg');
}

#restrauntDiv{
    background-image: url('../assets/restraunt.jpg');
}

#liqourStoreDiv{
    background-image: url('../assets/liquorStore.jpg');    
}

#satiataDiv{
    background-image: url('../assets/satiataRestraunt.jpg');
} */

.contactWrapper{
    display: flex;
    align-items: center;
}

.contactDiv{
    display: flex;
}

.contactDiv div{
    display: flex;
    align-items: center;
    margin-right: 20px;
}

.contactDiv img{
   height: 100%;
   width: 100%;
}