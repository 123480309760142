#basket {
    position: relative;
    font-family: Garamond;
  }
  
  #basket img {
    height: 30px;
  }
  
  #basketCounter {
    color: rgb(47, 47, 47);
    width: -moz-fit-content;
    width: fit-content;
    padding: 2px 5.5px;
    border-radius: 50%;
    background-color: rgb(255, 212, 42);
    border: 2px solid rgb(255, 212, 42);
    position: absolute;
    top: 7px;
    right: -10px;
    font-size: 0.7rem;
    box-shadow: -2px 0px 8px 2px rgba(177, 149, 149, 0.46);
    -moz-box-shadow: -2px 0px 8px 2px rgba(56, 56, 56, 0.46);
    box-shadow: -2px 0px 8px 2px rgba(56, 56, 56, 0.46);
  }
  
  #basketProducts {
    position: fixed;
    right: 0;
    background-color: #0a0a0acf;
    color:#F6F8FF;
    padding: 50px 5vw;
    box-sizing: border-box;
    width: fit-content;
    overflow-y: auto;
    height: 100vh;
    top: 0;
    display: flex;
    flex-direction: column;
    align-items: start;
    -webkit-backdrop-filter: blur(7.5px);
    backdrop-filter: blur(7.5px);
    transform: translateX(0);
    transition:transform 0.2s ease-out;
  }
  
  #basketProducts::-webkit-scrollbar {
    width: 12px; /* Width of the scrollbar */
  }
  
  #basketProducts::-webkit-scrollbar-track {
    background: #f1f1f1; /* Background of the scrollbar track */
  }
  
  #basketProducts::-webkit-scrollbar-thumb {
    background-color: #d3d3d3; /* Color of the scrollbar handle */
    border-radius: 10px; /* Roundness of the scrollbar handle */
    border: 3px solid #f1f1f1; /* Space around the scrollbar handle */
  }
  
  #basketProducts::-webkit-scrollbar-thumb:hover {
    background-color: #c1c1c1; /* Color of the scrollbar handle when hovered */
  }
  
  #basketCloseBtn {
    position: absolute;
    top: 0;
    left: 15px;
    cursor: pointer;
    font-size: 20px;
    font-weight: bold;
    color: rgb(47, 47, 47);
  }

  #basketProducts h2{
    border-bottom: 2px solid #fed126ff;
  }
  
  #basketProducts h2 img{
    height: 25px;
  margin-right: 15px;
  }

  #basketProducts ul {
    align-items: start;
    padding: 0;
    list-style-type: none;
  }
  
  #basketProducts ul li {
    padding: 10px 15px;
    box-sizing: border-box;
    border-bottom: 1px solid #fed126ff;
    width: 100%;
    display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: start;
  }
  
  #basketProducts ul li h3 {
    font-size: 1rem;
    display: inline-block;
    max-width: 230px;
    word-wrap: break-word;
    overflow-wrap: break-word;
    margin: 5px 0 10px;
  }

  #basketProducts .priceBox{
    display: flex;
  }
  
  #basketProducts ul li p {
    margin: 5px 15px 5px 0;
  }

  #basketProducts .quantity-controls img{
    height: 16px;
  }


  .cartLineWrapper{
    display: flex;
  }
  
  .quantity-control {
    flex-grow: none;
    display: flex;
    align-items: center;
    border-radius: 4px;
    overflow: hidden;
    height: 25px;
    margin-right: 20px;
  }
  
  .quantity-control button {
    background: none;
    border: none;
    padding: 5px;
    box-sizing: border-box;
    margin: 0;
    width: fit-content;
    cursor: pointer;
    font-size: 18px;
    color: rgb(47, 47, 47);
    display: flex;
  }

  .quantity-control button img{
    height: 100%!important;
  }
  
  .quantity-control span {
    padding: 10px 5px;
    font-size: 16px;
    color: rgb(47, 47, 47);
  }


  #basketProducts .clear-cart-btn img{
    height: 16px;
  }

  #basketProducts .cart-overview:first-of-type{
    margin-bottom: 25px;
  }

  #basketProducts .cart-overview{
    margin-left: 0;
    width: 100%;
    box-sizing: border-box;
    background-color: #2F2F2FA1;
    min-width: 400px;
  }

  #basketProducts .cart-overview h2{
    text-align: start;
  }

  
  #basketProducts a {
    border: none !important;
    margin: 0 auto;
  }
  
  #basketProducts a button {
    width: 100%;
    padding: 10px;
    background-color: rgb(255, 212, 42);
    color: rgb(47, 47, 47);
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  #basketProducts a button:hover {
    background-color: rgb(230, 192, 38);
  }

  

  .hide {
    visibility: hidden;
    z-index: -2;
    position: absolute;
    overflow: hidden;
    width: 0 !important;
    height: 0 !important;
    transform: translateX(100%)!important;
    transition:0.2s ease-out;
  }
  
  #basketProducts ul {
    flex-direction: column;
  }
  


  @media (max-width: 450px) {

    #basket a{
      border-bottom: none;
    }
  
  
  
  }
  