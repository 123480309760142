/* src/components/styling/login.css */

.login-wrapper {
  display: flex;
  justify-content: center;
  align-items: start;
  min-height: 100vh;
  background-color: rgb(242, 243, 236);
  padding: 100px 5vw;
  }
  
  .login-box {
    background-color: white;
    color: rgb(47, 47, 47);
    padding: 40px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    max-width: 500px;
    width: 100%;
    text-align: center;
  }
  
  .login-box h2 {
    margin-bottom: 20px;
    margin-top: 0;
  }
  
  .login-box form {
    display: flex;
    flex-direction: column;
  }
  
  .login-box input {
    margin-bottom: 15px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .login-box .btn {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    margin-bottom: 10px;
  }
  
  
  .login-box p {
    color: red;
  }
  