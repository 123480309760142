.payment-method-selector {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
  }
  
  .payment-method-list {
    display: flex;
    flex-direction: column;
  }
  
  .payment-method-item {
    padding: 10px;
    margin: 5px 0;
    cursor: pointer;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
    transition: background-color 0.2s ease, border 0.2s ease;
    display: flex;
  justify-content: start;
  align-items: center;
  height: 60px;
  box-sizing: border-box;
  }

  .payment-method-item img{
    width: 40px;
    margin-right: 10px;
  }
  
  .payment-method-item:hover {
    background-color: #e0e0e0;
  }
  
  .payment-method-item.selected {
    background-color: #e0e0e0;
    border: 1px solid rgb(85, 116, 110);
  }
  