#setMenuBox{
        width: 100vw;
        max-width: 100%;
        min-height: 100vh;  
        background-color: rgb(242, 243, 236);
        display: flex;
  justify-content: center;
}

#setMenuWrapper{
        padding: 75px 20px;
        max-width: 1200px;
        background: white;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        border-radius: 8px;
        background-color: rgb(242, 243, 236);
        color: rgb(47, 47, 47);
        width: 100%;
        box-sizing: border-box;        
}

#setMenuBox h2{
        border-bottom: 2px solid rgb(85, 116, 110);
  padding-bottom: 10px;
}

.activeMenuInfo{
        padding:50px 0;
        display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 100px;
}

.activeMenuInfo ul{
        list-style: none;
  padding: 0; 
}

.activeMenuInfo li{
display: grid;
grid-template-columns: repeat(2, 1fr); /* Creates 2 equal columns */
gap: 20px; 
}


@media (max-width: 1200px) {

        .activeMenuInfo{
            flex-direction: column;
            flex-direction: column;
    box-sizing: border-box;
    width: 100%;
    padding: 0;
        }
    
        .activeMenuInfo div{
            box-sizing: border-box;
            width: 100%;
        }

        .activeMenuInfo embed{
                width: 100%;
                border: 1px solid black;
 
        }

        .currentMenu{
                margin: 50px 0 15px;
        }
    
    }