#thankYouWrapper {
    width: 100vw;
    max-width: 100%;
    min-height: 100vh;
    background-color: #0A0A0A;
    display: flex;
    justify-content: center;
    align-items: center;
  box-sizing: border-box;
  }
  
  #thankYouBox {
    margin: 0 auto;
    padding: 100px 50px;
    max-width: 800px;
    background-color: #2F2F2F69;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    color:#F6F8FF;
    text-align: center;
    box-sizing: border-box;
    display: flex;
  flex-direction: column;
    font-family: Garamond;    
    margin: 0 5vw;
  }
  
  #thankYouBox h1 {
    margin-bottom: 20px;
    border-bottom: 2px solid rgb(255, 212, 42);
    padding-bottom: 10px;
    text-align: center!important;
  }
  
  #thankYouBox p:first-of-type {
    margin: 20px 0;
  }
  #thankYouBox p:last-of-type {
    margin: 0 0 20px;
  }
  
  #thankYouBox a{
    width: 300px;
    margin:20px auto 0;
  }

  .thankYouImage {
    width: 200px;
    height: auto;
    margin: 20px 0;
  }
  
  .btn-pulci-green {
    background-color: rgb(255, 212, 42);
    color: white;
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    cursor: pointer;
    text-decoration: none;
    color: rgb(47, 47, 47);
  }
  
  .btn-pulci-green:hover {
    background-color: rgb(203, 171, 43);
    color: rgb(47, 47, 47);
  }
  


  @media (max-width: 450px) {



    #thankYouBox{
      padding:15vw 5vw;
    }


    #thankYouBox a{
      width: 60%;
    }


  }