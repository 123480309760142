#ageWrapper{
  position: fixed;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  z-index: 1000;
  background-color: #0A0A0A;
  color:#F6F8FF; 
  display: flex;
  justify-content: center;
  font-family: Garamond;
  align-items: center;
  top: 0;
}

#ageBox{
  padding: 50px 100px;
  border-radius: 5px;
  background-color: #2F2F2F69;   
}

#ageLogoWrapper{
  margin:0 0 25px;
  display: flex;
  justify-content: center;
}

#ageLogoWrapper img{
  height: 100px;
}

#ageBox h1{
  margin: 0 0 25px;
  text-align: center!important;
}

#ageButtons{
  display: flex;
  justify-content: center;
  align-items: center;
}

#ageButtons .yes-btn{
  margin-right: 5%;
}

#ageButtons button,
#ageButtons a{
  width: 30vw;
  max-width: 130px;
}



#productBox{
    background-color: #0A0A0A;
    color:#F6F8FF;  
    box-sizing: border-box;
    font-family: "EB Garamond", serif;
}

#productBoxWrapper{    
  background-color: #0A0A0A;
    padding: 0 150px 200px;
  min-height: 100vh;
  max-width: 2500px; 
  margin: auto;
  box-sizing: border-box;
  position: relative;
  box-shadow: 0 0 7px 2px rgba(21, 21, 21, 0.23);
}

.productBoxBackgroundImage{
    position: absolute;
}

.productBoxBackgroundImage:nth-of-type(1){
    left: 0;
    top: 15%;
  height: 250px;
}

.productBoxBackgroundImage:nth-of-type(2){
   left: 0; 
   bottom: 20%;
  height: 250px;
}

.productBoxBackgroundImage:nth-of-type(3){
    right: 0;
    top: 20%;
  height: 250px;
}

.productBoxBackgroundImage:nth-of-type(4){
    right: 0;
  bottom:0; 
  height: 200px;
}

.productBoxBackgroundImage:nth-of-type(5){
    right: 20vw;
  bottom:10vh; 
  height: 100px;
}

.productBoxBackgroundImage:nth-of-type(6){
    left: 15vw;
  top:60vh; 
  height: 100px;
}



.productBoxTitle{
    width: 100%;
  text-align: center;
  z-index: 4;
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  align-items: center;
}

.productBoxTitle span{
  color: #fed126ff;
}

.productBoxTitle img{
    height: 100px;
    width:auto;
  margin: 100px 0 50px;
}

.productBoxTitle h1{
    font-size: 3.5rem;
    margin: 0;
}

.productBoxTitle span{
    font-size: 1.6rem;
    font-weight: 500;
}

#productContainer{
    margin-top: 100px;
    z-index: 4;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

}

.product{
  display: flex;
  margin-bottom: 25px;
  background-color: #2F2F2F69;
  backdrop-filter: blur(7.5px);
  padding: 25px;
  border-radius: 5px;
}

.productInfo{
    margin-left: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.productImageWrapper{
    background-color: #2F2F2F;
    width: 150px;
    height: 150px;
    display: flex;
    padding: 10px;
    box-sizing: border-box;
    border-radius: 5px;
}

.product img{
    height: 100%;
    margin:auto;
    width: auto;
}

.productTitle{
    font-weight: 800;
    margin: 0;
}


.productInfoSpan{
    width: 600px;
  text-align: center;
  font-size: 1.2rem;
}

.productButtons{
    margin-top: 15px;
    display: flex;
    align-items: center; /* Center elements vertically */
    height: 30px;
    width: 100%;
    justify-content: center;
}

.productButtons .hightlightsLink{
 width: fit-content;
}

.productButtons button,
.productButtons input,
.productButtons a{
  height: 30px;
  box-sizing: border-box; /* Ensures padding/margins don’t add extra size */
  margin: 0; /* Reset default margin if any */
}

.productButtons .hightlightsLink button{
margin-right: 15px;
}

.productButtons input{
  width: 72px;
  text-align: center;
}

.productButtons button{
    background-color: #fed126ff;
    color: #2F2F2F;
    font-family: "EB Garamond", serif;
    font-size: 1rem;
    padding: 5px 15px;
    border-radius: 5px;
  border: none;
  transition: background-color 0.2s ease-out;
  margin: 0 5px;
}

.productButtons .add-to-cart{
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.productButtons button:hover{
    background-color: rgb(193, 162, 41);
}

.productButtons button img {
  height: 100%; /* Ensure image inside the button scales to button size */
  width: auto;
}

#basketStatusMessage{
  text-align: center;
}