#contactFormWrapper {
    background-color: #f1f1f1;    
    color: #2f2f2f;
    padding: 50px;
    box-sizing: border-box;
    text-align: justify;
    border-radius: 5px;
    min-width: 500px;
    max-width: 500px;
    -webkit-box-shadow: 0px 6px 17px -7px rgba(0,0,0,0.37);
  -moz-box-shadow: 0px 6px 17px -7px rgba(0,0,0,0.37);
  box-shadow: 0px 6px 17px -7px rgba(0, 0, 0, 0.37);
  position: relative;
  }
  
  #contactFormWrapper h2 {
    text-align: center;
    margin-bottom: 20px;
    margin-top: 0;
  }
  
  #contactFormWrapper p {
    text-align: center;
    color: #2f2f2f;
    margin-bottom: 20px;
    
  }
  
  #contactFormWrapper form {
    width: 100%;
    margin: 0 auto;
  }
  
  #contactFormWrapper form div:last-of-type {
    margin-bottom: 20px;
  }
  
  #contactFormWrapper label {
    display: block;
    font-weight: bold;
    margin-bottom: 10px;
    color: rgb(255, 212, 41); /* Yellow color */
  }
  
  #contactFormWrapper input,
  #contactFormWrapper textarea {
    width: 100%;
    padding: 10px;
    border: 2px solid #55746e00;
    background-color: #F6F8FF;
    color: #2F2F2F;
    font-family: merriweather;
    border-radius: 5px;
    box-sizing: border-box;
  }
  
  #contactFormWrapper textarea {
    height: 150px;
  }
  
  #contactFormWrapper input:focus,
  #contactFormWrapper textarea:focus {
    outline: none;
    border: 2px solid #55746e;
  }
  
  #contactFormWrapper button {
    width: 100%;
    padding: 15px;
    background-color: rgb(255, 212, 41);
    border: none;
    color: rgb(47, 47, 47);
    text-transform: uppercase;
    font-family: merriweather;
    font-weight: bold;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-bottom: 25px;
  }
  
  #contactFormWrapper button:hover {
    background-color: rgb(214, 173, 7);
  }
  
  #statusMessage {
    font-size: 1rem;
    position: absolute;
    width: calc(100% - 100px);
    box-sizing: border-box;
    bottom: 25px;
  }
  
  #contactFormWrapper a {
    color: #55746e;
    font-weight: bold;
    text-align: center;
    display: block;
    margin-bottom: 30px;
    transition: 0.1s ease-out;
    cursor: pointer;
  }
  
  #contactFormWrapper a:hover {
    color: #405854; /* A slightly lighter shade of yellow */
  }
  