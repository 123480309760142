#adminBox{
    background-color: rgb(242, 243, 236);
    color:rgb(47, 47, 47);
    min-height: 100vh;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 100px;
}

#adminBox h2{
    margin: 50px 0;
}

#adminLinks{
    display: flex;    
}

#adminLinks a{
    margin: 0 10px;
}

#adminLinks button{
    padding: 15px;
    font-size: 1rem;
    border:none;
    border-radius: 5px;
    color:white;
}



@media (max-width: 500px) {

    #adminBox{
        padding: 100px 5vw;
    }

    #adminBox h2{
        text-align: center;
    }

    #adminLinks {
        flex-direction: column;
        align-items: stretch;
    }

    #adminLinks a{
        margin-bottom: 15px;
    }

    #adminLinks button{
        width: 100%;
        margin:0;
    }


}