/* Responsive adjustments */

#adminShippingWrapper{
    display: flex;
  justify-content: center;
  padding: 100px 5vw;
}


@media (max-width: 1200px) {

    #adminShippingWrapper tr td:first-of-type{
        margin-right: 25px;
    }

    #adminShippingWrapper .container{
        margin: 0;
        width: 100%;
    }

}
