/* uploadPdfComponent.css */

.upload-form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 50px;
    background-color: #F0EFF4;
    border-radius: 5px;
    max-width: 400px;
    margin: 20px auto;
    box-shadow: 0 0 7px 2px rgba(120, 117, 117, 0.23);
    margin-top:50px;
  }
  
  .upload-form embed{
    box-shadow: 0 0 7px 2px rgba(5, 5, 5, 0.15);
  }

  .upload-form h2 {
    margin: 0 0 15px;
    font-size: 24px;
    color: rgb(47, 47, 47);
    font-size: 1.4rem;
  }
  
  .upload-form input[type="file"] {
    padding: 10px;
    background-color: white;
    border-radius: 5px;
    border:none;
  }
  
  .upload-form input[type="text"] {
    padding: 10px;
    background-color: white;
    border:none;
    border-radius: 5px;
    font-size: 16px;
  }
  .upload-form input[type="text"]:focus {
    border-color: rgb(255, 212, 42); /* Change this to the desired color */
    outline: none; /* Optionally remove the default outline */
  }
  
  .upload-form button {
    background-color: rgb(85, 116, 110);
    color: #fff;
    border: none;
    padding: 10px 15px;
    cursor: pointer;
    border-radius: 5px;
    font-size: 16px;
    transition: 0.2s ease-in;
  }

  .upload-form button:disabled {
    background-color: rgb(47, 47, 47);
    cursor: default;
  }
  .upload-form button:disabled:hover {
    background-color: rgb(47, 47, 47);
    cursor: default;
    color: #fff;
  }
  
  
  .upload-form button:hover {
    background-color: rgb(250, 202, 32);
    color:rgb(47, 47, 47);
  }
  
  .upload-form .btn-setActive {
    margin-top: 10px;
  }
  
  .upload-form span{
    margin: auto;
  }