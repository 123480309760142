footer{
    height: 300px;
    width:100vw;
    max-width:100%;
    background-color: rgb(47, 47, 47);
    position: relative;
    display: flex;
    justify-content: space-between;
    color: #F6F8FF;
}

#menuSelectorBox{
    position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  background-color: #00000096;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;    
  font-family: merriweather;
}

.menuSelectorWrapper{
    padding: 50px 100px;
    background-color: #f1f1f1;
    border-radius: 5px;
    position: relative;
    color: #04030F;
    text-align: center;
}

.menuSelectorWrapper img{
    position: absolute;
    right: 20px;
    top: 20px;
    height: 25px;
    cursor: pointer;
}

.menuSelectorWrapper h3{
    margin-bottom: 25px;
    font-weight: 2;
}

.menuSelectButtons{
    display: flex;
  gap: 10px;
  justify-content: space-between;
  width: 100%;
}

.menuSelectButtons a{
    flex: 1;
}

#menuSelectorBox .btn-pulci{
background-color: rgb(255, 212, 41);
  color: rgb(47, 47, 47);
}

#menuSelectorBox .btn-pulci:hover{
background-color:rgb(214, 173, 7);
}
#menuSelectorBox .btn-satiata:hover{
    background-color: #551319;
}

#menuSelectorBox .btn-satiata{
    background-color: #811520;
    color: #fff;
}

#menuSelectorBox div button,
#menuSelectorBox div a button{
    flex: 1;
    padding: 10px 10px;
    text-align: center;
    box-sizing: border-box; 
    width: 100%;   
    
    border: none;
    border-radius: 4px;
    cursor: pointer;    
  font-family: merriweather;
  transition: 0.1s ease-out;
}

.footerCol{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.footerCol h3{
    width: 200px;
}


.footerCol button{
    width: 225px;
}

.footerAddressCol{
    width: 200px;
    margin-bottom: 15px;
    position: relative;
}

.footerAddressCol:last-of-type{
    margin-bottom: 0!important;
}

.footerAddressCol img{
    position: absolute;
  left: -30px;
  height: 20px;
  top: 0;
  bottom: 0;
  margin: auto;
}

.footerAddressCol h4{
    margin: 0;
}


.footerLogo{    
    height: 125px;
}

.footerHotLinks{
    display: flex;
}

.footerHotLinks a{
    color:#F6F8FF;
    text-decoration: none;
}

.footerHotLinks p{
    transition: 0.1s ease-out;
    cursor: pointer;
}

.footerHotLinks a:hover{
color: #5b7c76;
}

.linkSpacer{
    padding: 0 10px;
}

.copyRight{
    position: absolute;
    bottom: 5px;
   text-align: center;
   width: 100%;
    margin: auto;
}
