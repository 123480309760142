@media (max-width: 1200px) {

#mobileNavSpacer{
    height: 100px;
    width: 100%;
    transition: 0.2s ease-out;
}

#mobileNavHeader{

    height: 100px;
    width: 100vw;
    padding: 15px calc(5vw + 25px);
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    position: fixed;
    z-index: 9;
    background-color: #2f2f2f;
    top:0;
    transition: 0.2s ease-out;
    }

#mobileNavBackground{
    height: 100vh;
  position: fixed;
  width: 100vw;
  background-color: #0000005c;
  z-index: 10;
  top: 0;
  opacity: 0;
  transition: 0.2s ease-out; 
  visibility: hidden;
}

.header-collapsed{
    transform: translateY(-100%);
}


#mobileNavHeader img{
    height: 80%;
}

nav .navLogo{
    margin: 0;
}

#mobileNavHeader img:last-of-type{
height: 50%;
}

.navWrapper{
    height: 0!important;
}

nav{
    position: fixed;
    margin: 0;
    width: fit-content;
    justify-content: start;
    padding:0;
    transform: translateX(-100%);    
    height: 100vh;
}

.navInnerWrapper{
    
    height: 100%;
    position: relative;
    padding: 50px 5vw 0;
    box-sizing: border-box;
    z-index: 15;
    flex-grow: 1;
    min-height: 775px;
    justify-content: start;
}

.show-mobile-nav{
    transform: translateX(0%)!important;
    overflow-x: visible;
    overflow-y: auto;
    opacity: 1!important;
    display: flex;
    visibility: visible!important;
    overflow-x: hidden;
}

nav #xSvg{
    position: absolute;
    margin: 0;
    height: 25px;
    top: 15px;
    right: 15px;
}

nav ul{
    flex-direction: column;
    align-items: start;
    margin-top: 25px;
}

nav li{
    padding: 10px 5vw;
    align-self: stretch;
    text-align: start!important;
}
nav li a{
padding-left: 5px;
}

nav li:last-of-type{
justify-content: center;
}

nav img{
    margin-bottom: 50px;
}

nav a{
    display: block;
    width: 100%;
    margin-bottom: 5px;
}


#basket{
    display: flex;
  justify-content: center;
}

#basketCounter{
    margin-left: 18px;
    right: auto!important;
}

#basket img{
    margin-bottom: 25px;
}

.navBottomSpacer{
    min-height: 100px;
    width: 100%;
}

.navBottomButtons{
    margin: 0;
    width: calc(100% + 10vw);
    
}

.navBottomButtons div{
    width: 100%;
    text-align: center;
    height: 50px;
    justify-content: center;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    font-size: 0.9rem;
}

.menuButton{
 background-color: rgb(85, 116, 110);
 color: white;
 font-weight: 500;
}

#menuSelectorBox{
    width: 100vw!important;
}

.reserveA{
    border: none;
    font-weight: 500;
}

.reserveA:hover{
    border: none;
}

.reserveButton{ 
  background-color: #fed126;
  color: #04030F;

}

@media (max-height: 775px) {

    .navInnerWrapper{
    
    }

}

}