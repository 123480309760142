/* src/components/styling/adminOrders.css */
#adminOrdersWrapper {
  width: 100vw;
  max-width: 100%;
  min-height: 100vh;  
  background-color: rgb(242, 243, 236);
}

.container {
  padding: 75px 40px;
  max-width: 1200px;
  min-height: 100vh;
  background: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background-color: rgb(242, 243, 236);
  color: rgb(47, 47, 47);
  width: 100%;
  box-sizing: border-box;
}


.container h1 {
  margin: 0 0 50px 0;
  border-bottom: 2px solid rgb(85, 116, 110);
  padding-bottom: 10px;
}

.filters {
  display: flex;
  justify-content: start;
  margin-bottom: 20px;
  align-items: center;
}

.filters select{
  margin-left: 5px;
}

.filters label {
  margin-right: 20px;
}

.filters input {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  flex-grow: 1;
  max-width: 500px;
  min-width: 200px;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

thead {
  border-bottom: 1px solid rgb(85, 116, 110);
  cursor: pointer;
}

thead th {
  padding: 10px;
  text-align: left;
}

tbody td {
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

table button{
  margin-right: 5px;
}


.action-link {
  color: #007BFF;
  text-decoration: none;
}

.action-link:hover {
  text-decoration: underline;
}



/* Adjust container layout and padding for mobile */
@media (max-width: 1200px) {


  .container {
    padding: 20px;
    margin: 100px auto 0 auto;
    width: 90%;
  }

  .container h1 {
    font-size: 1.5em;
    margin-bottom: 30px;
  }

  /* Stack filters vertically and adjust padding */
  .filters {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }

  .filters label,
  .filters input,
  .filters select {
    width: 100%;
    box-sizing: border-box;
  }

  /* Adjust table layout for smaller screens */
  table {
    font-size: 0.9em;
    overflow-x: auto;
    display: block;
  }

  thead {
    display: none; /* Hide table headers for a simpler layout */
  }

  tbody {
    display: block;
    width: 100%;
  }

  tbody tr {
    display: flex;
    flex-direction: column;
    margin-bottom: 25px;
    border-bottom: 1px solid #ddd;
  }

  tbody td {
    display: flex;
    justify-content: space-between;
    padding: 8px 0;
  }

  tbody td:last-of-type {
    justify-content: center;
    border-bottom: none;
  }

  /* Adjust button size for mobile */
  table button,
  .btn-download,
  .btn-pulci-green {
    padding: 8px 12px;
    font-size: 0.9em;
  }
}

